<template>
  <div>
    <picker
      document-types="work-queues"
      :label="$t('t.WorkQueue')"
      :selected-id.sync="computedWorkQueueId"
      :clearable="false"
      :dense="dense"
      :rules="required"
      :disabled="disabled"
      :attach="false"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedWorkQueueId: {
      get () { return this.dataWorkQueueId },
      set (v) {
        this.dataWorkQueueId = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataWorkQueueId: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'assign-to-work-queue', workQueueId: this.dataWorkQueueId }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataWorkQueueId = v?.workQueueId
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
